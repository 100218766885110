import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Moment from "moment"

const GridClinics = props => {
  const [data, setData] = useState([])

  useEffect(() => {
    const now = Moment.now()
    setData(
      props.data.filter(item => (item.live) && Moment(item.clinicDate) >= now)
    )
  }, [props.data])

  useEffect(() => {
    Moment.locale("en")
  }, [])

  if (!data || data.length === 0) {
    return (
      <section className="events-grid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4>No upcoming events</h4>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="events-grid">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-area">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Clinic</th>
                    <th scope="col">Host</th>
                    <th scope="col">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={`${index}-clinic-grid`}>
                        <th scope="row">
                          {Moment(item.clinicDate.split("T")[0]).format(
                            "MMM D, YYYY"
                          )}
                        </th>
                        <td>
                          <h6>
                            <Link to={`/officials/clinics/${item.slug}`}>
                              {item.clinicname}
                            </Link>
                          </h6>
                        </td>
                        <th scope="row">{item.host}</th>
                        <td>
                          <a href="https://www.google.com/maps">
                            {item.location}
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GridClinics
